
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("品牌新增", ["品牌管理;product_brands"])
      } else {
        setCurrentPageBreadcrumbs("品牌编辑", ["品牌管理;product_brands", route.params.id.toString()])
      }
    });

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const product_brand = ref({
      name: ""
    })

    const onCancel = () => {
      router.push({ name: "product_brands" });
    };

    const isNew = !route.params.id;

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          if (isNew) {
            store
              .dispatch(
                Actions.CREATE_PRODUCT_BRAND,
                product_brand.value
              )
              .then(() => {
                router.push({ name: "product_brands" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_PRODUCT_BRAND, {
                id: route.params.id,
                values: product_brand.value,
              })
              .then(() => {
                router.push({ name: "product_brands" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          }
        } else {
          return false;
        }
      });
    };

    if (!isNew) {
      store
        .dispatch(Actions.GET_PRODUCT_BRAND, route.params.id)
        .then(() => {
          product_brand.value = store.getters.currentProductBrand
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    return {
      onSubmit,
      product_brand,
      rules,
      onCancel,
      formRef,
    };
  },
});
